
import { Component, Vue, Watch } from 'vue-property-decorator'
import axios from 'axios'
import CusdisComment from '@/components/CusdisComment.vue'
import Gallery from '@/components/Gallery.vue'
import Frame from '@/components/Frame.vue'
import { Category, Section, Image } from '@/types'

@Component({
  components: {
    CusdisComment,
    Frame,
    Gallery,
  },
})
export default class Home extends Vue {
  sections: Section[] = []
  categories: Record<string, Category> = {}
  galleryImageURL: string | null = null
  galleryImageDesc: string | null = null
  badges: string[] | null = null
  location?: string
  unsplashID?: string
  downloadURL?: string

  created() {
    // document.title =
    axios.get('./photos.json').then((response) => {
      this.categories = response.data.categories
      this.sections = response.data.sections
    })
  }

  @Watch('currentCategory')
  onChildChanged(category: Category) {
    if (category && category.title) {
      document.title = `${category.title} - Ethan's Photo Gallery`
    } else {
      document.title = "Ethan's Photo Gallery"
    }
  }

  get currentCategory(): Category | undefined {
    return this.categories[this.currentCategoryID]
  }

  get currentCategoryID(): string {
    return this.$route.params.section ?? 'film'
  }

  getSections(category: string): Section[] {
    if (!this.categories[category]) {
      return []
    }
    return this.categories[category].sections
      .map((idx) => {
        return this.sections[idx]
      })
      .filter((x) => x)
  }

  clickImage(image: Image) {
    this.galleryImageURL = image.url
    this.galleryImageDesc = (image.desc ?? '').replace(/=/g, ' ')
    this.badges = image.badges || null
    this.location = image.location
    this.unsplashID = image.unsplashID
    this.downloadURL = image.downloadURL
  }
}
