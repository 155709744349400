
import { Component, Prop, Vue } from 'vue-property-decorator'
import Iconfont from '@/components/Iconfont.vue'
import { Badge } from '@/types'
import allBadges from '@/allBadges'

@Component({
  components: { Iconfont },
})
export default class Gallery extends Vue {
  currentHint: string | null = null
  currentLink: string | null = null

  @Prop() private imageURL!: string | null
  @Prop() private imageDesc!: string | null
  @Prop() private badgeNames!: string[] | null
  @Prop() private location?: string
  @Prop() private unsplashID?: string
  @Prop() private downloadURL?: string

  get badges(): Badge[] {
    if (!this.badgeNames) {
      return []
    }

    let retBadges = this.badgeNames
      .map((badge) => {
        if (allBadges[badge] && !allBadges[badge].template) {
          return allBadges[badge]
        }
      })
      .filter((b) => b) as Badge[]

    if (this.location) {
      retBadges.unshift({
        ...allBadges['location'],
        hint: this.location,
      })
    }

    if (this.unsplashID) {
      retBadges.unshift({
        ...allBadges['unsplash'],
        link: `https://unsplash.com/photos/${this.unsplashID}`,
      })
    }

    if (this.downloadURL) {
      retBadges.push({
        ...allBadges['download'],
        link: this.downloadURL,
        download: true,
      })
    }

    return retBadges
  }
  badgeMouseOut(badge: Badge) {
    if (!window.matchMedia('(pointer: coarse)').matches) {
      this.currentHint = null
      this.currentLink = null
    }
  }
  badgeClick(badge: Badge) {
    if (!window.matchMedia('(pointer: coarse)').matches && badge.link) {
      open(badge.link, '_blank')
    }
  }
}
