
export default {
  props: {
    host: {
      type: String,
      default: 'https://cusdis.com',
    },
    appID: {
      type: String,
      required: true,
    },
    pageID: {
      type: String,
      default: () => {
        return location.pathname
      },
    },
  },
  computed: {
    currentLocation() {
      return window.location
    },
    currentTitle() {
      return document.title
    },
  },
}
